<template>
  <v-card>
    <v-card-text class="px-8 pt-8 text-center">
      <span class="body-1" v-html="$t(responseObject.message)" />
    </v-card-text>
    <v-card-actions>
      <v-row justify="end" class="ma-0">
        <v-btn text color="primary" @click="close">
          {{ buttonText }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'responseDialog',
  props: {
    responseObject: Object,
    buttonText: {
      type: String,
      default: 'OK',
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>

</style>
