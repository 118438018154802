<template>
  <v-container fluid class="primary-image-bg py-7">
    <v-container>
      <v-row align="center">
        <v-col cols="12" sm="7">
          <h1 class="display-2" style="width: 100%;">
            {{ $t(`${title}`) }}
          </h1>
          <h2 class="display-1">
            {{ $t(`${subtitle}`) }}
          </h2>
        </v-col>
        <v-col cols="12" sm="5">
          <v-img v-if="image" :src="`${publicPath}public_storage/${image}`" height="160" width="160" class="mx-auto"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: 'HeroWithImage',
    props: {
      backgroundImage: {
        type: String,
        default: 'images/default-slider-background.jpg',
      },
      title: String,
      subtitle: String,
      image: String,
    },
    data() {
      return {
        //
      };
    },
    methods: {
      //
    },
  };
</script>

<style scoped>

</style>
