<template>
  <div v-frag>
    <!-- GENERAL INFOS -->
    <v-container class="my-10" v-if="step === 1">
      <v-row>
        <v-col cols="12">
          <div v-html="legalText"></div>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
          <v-checkbox v-model="serviceRequestForm.generalInfos"
                      :label="$t('acceptCheckbox.generalInformation')"
                      :rules="inputRules.acceptInformation"
          >
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row justify="end" class="mx-0 mt-5">
        <v-btn class="primary"
               :disabled="!serviceRequestForm.generalInfos"
               @click="nextStep">
          {{ $t('button.next') }}
        </v-btn>
      </v-row>
    </v-container>
    <!-- SERVICE REQUEST FORM -->
    <v-container class="my-5" v-if="step === 2">
      <v-form v-model="serviceRequestFormIsValid">
        <v-row>
          <!-- FORM HEADER -->
          <v-col cols="12" md="4">
            <v-card :class="`fill-height ${formHeaderBackgroundColor}`">
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'" class="fill-height">
                <v-row class="mx-0 fill-height" align-content="space-between">
                  <v-col cols="12">
                    <v-img :src="`${publicPath}public_storage/${brand.imagePath}`" width="140" height="140" class="mx-auto" />
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-icon color="white" x-large v-show="$vuetify.breakpoint.mdAndUp" class="mb-3">
                      mdi-email
                    </v-icon>
                    <div class="text-center headline align-self-center white--text">
                      {{ $t('ourEmail') }}:<br><a class="body-1 white--text">info@tmx.hu</a>
                    </div>
                  </v-col>
                  <!--<v-col cols="12" class="text-center">
                    <v-icon color="white" x-large v-show="$vuetify.breakpoint.mdAndUp" class="mb-5">
                      mdi-phone
                    </v-icon>
                    <div class="text-center headline align-self-center white--text">
                      {{ $t('ourPhoneNumber') }}:<br><a class="body-1 white--text">+36-1/58-58-381</a>
                    </div>
                  </v-col>-->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- FORM HEADER END -->
          <!-- KÉSZÜLÉK ADATOK START -->
          <v-col cols="12" md="8">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
                <span class="subtitle-1">{{ $t('stepper.deviceDetails') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete v-model="serviceRequestForm.serviceRequest.brand"
                                    :items="selectFieldOptions.brands"
                                    :label="$t('inputLabels.manufacturer')"
                                    item-value="id"
                                    item-text="name"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    filled
                                    return-object
                                    required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="serviceRequestForm.serviceRequest.productGroup"
                              :items="selectFieldOptions.productGroups"
                              item-text="name"
                              item-value="id"
                              :no-data-text="$t('noDataText.general')"
                              :label="$t('inputLabels.productGroup')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled
                              return-object
                              required>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="serviceRequestForm.serviceRequest.productTypeFull"
                              :items="selectFieldOptions.productTypes"
                              item-text="productTypeShoppingName"
                              item-value="productTypeId"
                              :no-data-text="$t('noDataText.general')"
                              :label="$t('inputLabels.productType')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled
                              return-object
                              required>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" sm="6" md="4" v-if="serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier1">
                        <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn1"
                                      :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier1"
                                      :rules="inputRules.imeiRule"
                                      :messages="$t(`${imeiHint}`)"
                                      :hint="$t('imeiHint')"
                                      v-mask="imeiMask"
                                      counter="15"
                                      maxlength="15"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier2">
                        <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn2"
                                      :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier2"
                                      :hint="$t('inputMessages.optionalField')"
                                      v-mask="imeiMask"
                                      counter="15"
                                      maxlength="15"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier3">
                        <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn3"
                                      :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier3"
                                      :hint="$t('inputMessages.optionalField')"
                                      :rules="inputRules.snRule"
                                      persistent-hint
                                      filled>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="serviceRequestForm.serviceRequest.networkLock"
                              :items="selectFieldOptions.networkLock"
                              item-text="name"
                              item-value="id"
                              :label="$t('inputLabels.simLock')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              disabled
                              filled
                              return-object
                              required>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="serviceRequestForm.serviceRequest.brand.id === 3">
                    <v-text-field v-model="serviceRequestForm.serviceRequest.rmaNumber"
                                  :label="$t('inputLabels.rma')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="serviceRequestForm.serviceRequest.warrantyType"
                              :items="selectFieldOptions.warrantyType"
                              item-text="name"
                              item-value="id"
                              :label="$t('inputLabels.warrantyType')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled
                              return-object
                              disabled
                              required>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="serviceRequestForm.serviceRequest.dateOfPurchase"
                        persistent
                        width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                            :label="$t('inputLabels.dateOfPurchase')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            readonly
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                     :first-day-of-week="1"
                                     :locale="$i18n.locale"
                                     :max="maxDate"
                                     min="2010-01-01" @input="$refs.dialog.save(serviceRequestForm.serviceRequest.dateOfPurchase)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">{{ $t('button.cancel') }}</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" class="py-0">
                      <span class="caption">
                        {{ $t('attachDocs') }}
                      </span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-file-input v-model="attachment.invoice"
                                  :label="$t('inputLabels.invoice')"
                                  accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                  counter
                                  show-size
                                  filled
                                  ref="valami">
                      <template v-slot:selection="{text}">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-file-input v-model="attachment.warrantyCard"
                                  :label="$t('inputLabels.warrantyTicket')"
                                  accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                  counter
                                  show-size
                                  filled>
                      <template v-slot:selection="{text}">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="serviceRequestForm.serviceRequest.errorDescription"
                                :label="$t('inputLabels.troubleDescription')"
                                :rules="inputRules.lenghtRules"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                :counter='3000'
                                filled
                                required>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- KÉSZÜLÉK ADATOK END -->
          <!-- ÜGYFÉL ADATOK START -->
          <v-col cols="12" lg="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
                <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.lastName"
                                  :label="$t('inputLabels.lastName')"
                                  :rules="inputRules.noSpecialCharAndReq"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  required
                                  :disabled="$store.state.authModule.isAuthenticated">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.firstName"
                                  :label="$t('inputLabels.firstName')"
                                  :rules="inputRules.noSpecialCharAndReq"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  required
                                  :disabled="$store.state.authModule.isAuthenticated">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.email"
                                  :label="$t('inputLabels.email')"
                                  :rules="inputRules.emailRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  required
                                  :disabled="$store.state.authModule.isAuthenticated">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.phoneNumber"
                                  :label="$t('inputLabels.phone')"
                                  :rules="inputRules.phoneRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  prefix="+36-"
                                  v-mask="mask"
                                  filled
                                  required
                                  :disabled="$store.state.authModule.isAuthenticated">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- ÜGYFÉL ADATOK END -->
          <!-- CÍM ADATOK START -->
          <v-col cols="12" lg="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
                <span class="subtitle-1">{{ $t('shippingAddress') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="deliveryInAddressString"
                                  :label="$t('shippingAddress')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  append-icon="mdi-pencil"
                                  readonly
                                  @click="deliveryInAddressModal = true"
                                  @click:append="deliveryInAddressModal = true">
                    </v-text-field>
                  </v-col>
                  <!--v-col cols="12" class="pb-0">
                    <v-checkbox v-model="billingAddressIsEqualdeliveryInAddress"
                                class="mt-0">
                      <template slot="label">
                        <span class="body-2">
                          {{ $t('invoicingAddressIsEqualShippingAddress') }}
                        </span>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-text-field v-model="billingAddressString"
                                  :label="$t('invoicingAddress')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  append-icon="mdi-pencil"
                                  readonly
                                  :disabled="billingAddressIsEqualdeliveryInAddress"
                                  @click="billingAddressModal = true"
                                  @click:append="billingAddressModal = true">
                    </v-text-field>
                  </v-col-->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- CÍM ADATOK END -->
          <!-- REGISZTRÁCIÓ START -->
          <v-col cols="12" v-if="(selectedService && selectedService.isRegistrationVisible) && !$store.state.authModule.isAuthenticated">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-shield-account</v-icon>
                <span class="subtitle-1">{{ $t('accountRegistration') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12" class="body-1 pb-0">
                    {{ $t('accountRegistrationQuestionOnForm') }}
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-radio-group v-model="serviceRequestForm.accountRegistration"
                                   :rules="inputRules.generalRules"
                                   :hint="$t('inputMessages.requiredField')"
                                   persistent-hint>
                      <v-radio value="yes" :label="$t('inputLabels.accountRegistrationTrue')"></v-radio>
                      <v-radio value="no" :label="$t('inputLabels.accountRegistrationFalse')"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row v-show="serviceRequestForm.accountRegistration === 'yes'">
                    <v-col cols="12">
                      <p class="body-1">
                        Kérjük a lenti mezőben adj meg egy jelszót. Ezzel tudsz majd bejelentkezni a felhasználói fiókodba. A bejelentkezési e-mail cím az Ügyfél adatoknál megadott (<b>{{ serviceRequestForm.customer.email }}</b>) e-mail címed lesz.
                      </p>
                      <p>
                        A jelszónak kötelezően tartalmaznia kell minimum 1db kis-, 1 db nagybetűt és minimum 1 db számot. A jelszó hossza minimum 8 karakter kell hogy legyen!
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" offset-sm="3">
                      <v-text-field v-model="serviceRequestForm.customer.pass"
                                    :label="$t('inputLabels.password')"
                                    :rules="serviceRequestForm.accountRegistration === 'yes' ? inputRules.min8 : []"
                                    :hint="serviceRequestForm.accountRegistration === 'yes' ? $t('inputMessages.requiredField') : ''"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show1 ? 'text' : 'password'"
                                    persistent-hint
                                    counter="30"
                                    filled
                                    required
                                    @click:append="show1 = !show1">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- REGISZTRÁCIÓ END -->
        </v-row>
        <!-- CHECKBOXES -->
        <v-row>
          <v-col cols="12" class="py-0">
            <v-checkbox
                v-model="serviceRequestForm.gdpr"
                :rules="inputRules.gdprRules"
                required
                class="ma-0"
            >
              <template slot="label">
                <span v-show="$i18n.locale === 'hu-HU'" class="body-2">Az <a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Adatvédelmi tájékoztatót</a>&nbsp;elolvastam és elfogadom.</span>
                <span v-show="$i18n.locale === 'en-EN'" class="body-2">I have read and accepted the&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Privacy Policy</a>.</span>
                <span v-show="$i18n.locale === 'de-DE'" class="body-2">Ich habe die&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Datenschutzbestimmungen</a> gelesen und akzeptiere sie.</span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-checkbox
                v-model="serviceRequestForm.termsAndCond"
                :rules="inputRules.gdprRules"
                required
                class="ma-0"
            >
              <template slot="label">
                            <span v-show="$i18n.locale === 'hu-HU'" class="body-2">
                              Az&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_hu.pdf`" target="_blank" @click.stop>Általános Szerződési Feltételeket</a>&nbsp;elolvastam és elfogadom.
                            </span>
                <span v-show="$i18n.locale === 'en-EN'" class="body-2">
                              I have read and accepted the&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_en.pdf`" target="_blank" @click.stop>Terms and Conditions</a>.
                            </span>
                <span v-show="$i18n.locale === 'de-DE'" class="body-2">
                              Ich habe die&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_de.pdf`" target="_blank" @click.stop>AGB</a> gelesen und akzeptiere sie.
                            </span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-row justify="end">
              <v-btn large color="primary" :disabled="!serviceRequestFormIsValid" @click="sendContactForm">
                {{ $t('button.send') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <!-- SZÁLLÍTÁSI CÍM MODAL START -->
    <v-dialog v-model="deliveryInAddressModal" max-width="800">
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
          <span class="white--text">{{ $t('shippingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="deliveryInAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-stepper v-model="shippingAddressStepper">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-select v-model="customerShippingAddress"
                        :items="selectFieldOptions.customerShippingAddresses"
                        item-text="label"
                        item-value="id"
                        :label="$t('inputLabels.selectShippingAddress')"
                        :rules="inputRules.generalRules"
                        :hint="$t('inputMessages.requiredField')"
                        persistent-hint
                        filled
                        return-object
                        required>
              </v-select>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
              <v-card-text class="pa-6">
                <v-form v-model="deliveryInAddressForm">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                      :items="selectFieldOptions.postalCodes"
                                      item-text="address.postalCode"
                                      item-value="address.postalCode"
                                      :search-input.sync="postalCodeSearch"
                                      :label="$t('inputLabels.postalCode')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      maxlength="4"
                                      required
                                      @change="onPostalCodeSelect"
                                      @click:clear="clearAllAddressInputs"
                                      clearable>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.city"
                                      :items="selectFieldOptions.cities"
                                      item-value="address.city"
                                      item-text="address.city"
                                      :search-input.sync="citySearch"
                                      :label="$t('inputLabels.city')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      required
                                      clearable
                                      @change='getPostalCodeByCity'
                                      @click:clear="clearAllAddressInputs">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-combobox v-model="streetIdeiglenes"
                                  :items="selectFieldOptions.streets"
                                  item-value="address.street"
                                  item-text="address.street"
                                  :search-input.sync="streetSearch"
                                  :label="$t('inputLabels.publicPlaceName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('inputMessages.noDataText')"
                                  persistent-hint
                                  filled
                                  required
                                  @change="onPublicPlaceSelect"
                                  clearable>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                    :label="$t('inputLabels.streetNumber')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    required
                                    clearable>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="serviceRequestForm.deliveryInAddress.other"
                                  :label="$t('infoToDeliver')"
                                  :rules="inputRules.noSpecialChar"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  clearable
                                  filled
                                  clear-icon="mdi-close"
                                  @keydown.prevent.enter="preventEnter"
                                  maxlength="50"
                                  counter="50">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn text @click="deliveryInAddressModal = false">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
                  {{ $t('button.save') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <!-- SZÁLLÍTÁSI CÍM MODAL END -->
    <!-- SZÁMLÁZÁSI CÍM MODAL START -->
    <!--v-dialog v-model="billingAddressModal" max-width="800">
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
          <span class="white--text">{{ $t('invoicingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="billingAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-form v-model="billingAddressForm">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="serviceRequestForm.billingAddress.billingName"
                              :label="$t('invoicingName')"
                              :rules="inputRules.required"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="serviceRequestForm.billingAddress.taxNumber"
                              :label="$t('vatNumber')"
                              :hint="$t('inputMessages.optionalField')"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="serviceRequestForm.billingAddress.postalCode"
                                :items="selectFieldOptions.postalCodesInvoice"
                                item-text="address.postalCode"
                                item-value="address.postalCode"
                                :search-input.sync="postalCodeSearchInvoice"
                                :label="$t('inputLabels.postalCode')"
                                :rules="inputRules.phoneRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                maxlength="4"
                                required
                                @change="onPostalCodeSelectInvoice"
                                @click:clear="clearAllAddressInputsInvoice"
                                clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="serviceRequestForm.billingAddress.city"
                                :items="selectFieldOptions.citiesInvoice"
                                item-value="address.city"
                                item-text="address.city"
                                :search-input.sync="citySearchInvoice"
                                :label="$t('inputLabels.city')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                clearable
                                @click:clear="clearAllAddressInputsInvoice">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-combobox v-model="streetIdeiglenesInvoice"
                            :items="selectFieldOptions.streetsInvoice"
                            item-value="address.street"
                            item-text="address.street"
                            :search-input.sync="streetSearchInvoice"
                            :label="$t('inputLabels.publicPlaceName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            :no-data-text="$t('inputMessages.noDataText')"
                            persistent-hint
                            filled
                            required
                            @change="onPublicPlaceSelectInvoice"
                            clearable>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="serviceRequestForm.billingAddress.streetNumber"
                              :label="$t('inputLabels.streetNumber')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              persistent-hint
                              filled
                              required
                              clearable>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="serviceRequestForm.billingAddress.other"
                            :label="$t('otherDescription')"
                            :hint="$t('inputMessages.optionalField')"
                            persistent-hint
                            clearable
                            filled
                            clear-icon="mdi-close"
                            maxlength="50"
                            counter="50">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="billingAddressModal = false">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn class="primary" @click="saveBillingAddressData" :disabled="!billingAddressForm">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog-->
    <!-- SZÁMLÁZÁSI CÍM MODAL END -->
    <!-- RESPONSE DIALOGS START -->
    <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
      <loading-dialog v-bind:loading-message="loadingDialog.message" />
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="responseDialog.response"
                       v-on:close-dialog="closeResponseDialog"/>
    </v-dialog>
    <v-dialog v-model="noFileDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="noFileDialog.response"
                       v-on:close-dialog="closeNoFileDialog"/>
    </v-dialog>
    <!-- RESPONSE DIALOGS END -->
    <!-- NOT VALID PDF DIALOG START -->
    <v-dialog v-model="attachmentChecker.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="attachmentChecker.response"
                       v-on:close-dialog="closeNotValidPdfDialog"/>
    </v-dialog>
    <!-- NOT VALID PDF DIALOG END -->
  </div>
</template>

<script>
import ResponseDialog from 'src/components/dialogs/ResponseDialog';
import LoadingDialog from 'src/components/dialogs/LoadingDialog';
import dayjs from "dayjs";
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE,
  HEREGETPOSTALCODESBYCITY,
} from 'src/utils/hereAPI';
import { getToken, getUid } from 'src/utils/jwtHelper';

export default {
  name: 'MobileProviderGaranciaForm',
  components: {
    ResponseDialog, LoadingDialog,
  },
  props: {
    mobilProviderId: {
      type: Number,
      default: 999,
    },
    mobilProviderName: {
      type: String,
      default: 'EMPTY',
    },
    brand: {
      type: Object,
      default() {
        return {};
      }
    },
    isReusedForm: {
      type: String,
      default: 'not',
    }
  },
  async beforeMount() {
    //
  },
  async mounted() {
    // console.log('this.brand: ', this.brand);
    // console.log('this.mobilProviderId: ', this.mobilProviderId);
    this.isUserCanSeeTheForm = await this.CheckIsUserCanSeeTheForm();
    if (this.isUserCanSeeTheForm) {
      await this.setFormHeaderBackgroundColorAndFormName();
      await this.callShowForm();
      await this.callShowPaymentMethodOfCustomerType();
      await this.setDates();
      await this.setServiceRequestFormCountry();
      await this.setNetworkLock();
      await this.setWarrantyType();
      await this.setAvailableServiceIdAndFormType();
    }
    // await this.setProductGroupsSelectField();
    if (await getToken()) {
      if (await getUid()) {
        await this.setCustomerDatas();
        await this.setCustomerAddresses();
        this.shippingAddressStepper = 1;
        this.billingAddressStepper = 1;
        if (this.isReusedForm !== 'not') {
          this.serviceRequestForm.serviceRequest = await this.getRequestById(this.isReusedForm);
        }
      }
    }
  },
  data() {
    return {
      isUserCanSeeTheForm: true,
      // igény leadási form
      serviceRequestFormIsValid: false,
      serviceRequestForm: {
        formType: 999,
        processType: 1,
        prevCrmRequestId: null,
        prevGsmWorkSheetId: null,
        sourceSystem: null,
        externalId: null,
        serviceRequest: {
          partnerWorksheetNumber: null,
          prevCrmRequestId: null,
          prevGsmWorkSheetId: null,
          availableServiceId: null,
          brand: {},
          productGroup: {},
          productTypeFull: null,
          productType: {},
          uniqueIdentifierIn1: null,
          uniqueIdentifierIn2: null,
          uniqueIdentifierIn3: null,
          warrantyType: null,
          dateOfPurchase: null,
          errorDescription: '',
          networkLock: {},
          mobilProvider: {},
          insuranceNumber: '',
          rmaNumber: '',
          accessories: null,
          paymentMethod: { id: null, name: null, lang: null},
          deductible: null,
          isLoan: null,
          otherComment: '',
        },
        customer: {
          id: null,
          customerType: 'B2C',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: '',
        },
        deliveryInAddress: {
          id: null,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
          isCompany: 0,
        },
        deliveryOutAddress: {},
        billingAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        gdpr: false,
        termsAndCond: false,
        generalInfos: false,
        accountRegistration: null,
      },
      // szállítási cím
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      // számlázási cím
      billingAddressString: '',
      billingAddressForm: false,
      billingAddressModal: false,
      postalCodeSearchInvoice: '',
      postalCodeSearchIsLoadingInvoice: false,
      citySearchInvoice: '',
      citySearchIsLoadingInvoice: false,
      streetSearchInvoice: '',
      streetSearchIsLoadingInvoice: false,
      streetIdeiglenesInvoice: '',
      // számlázási cím megegyezik a szállítási címmel
      billingAddressIsEqualShippingAddress: false,
      // user által megadott címek kezeléséhez
      customerShippingAddress: {},
      customerBillingAddress: {},
      shippingAddressStepper: 2,
      billingAddressStepper: 2,
      selectedService: {},
      legalText: '',
      step: 1,
      show1: false,
      mask: '##-###-####',
      taxNumberMaskHU: '########-#-##',
      imeiMask: '###############',
      modal: false,
      contactFormIsValid: false,
      contactFormCheckbox: false,
      imeiHint: '',
      maxDate: null,
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_CONTACT_FORM',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
      noFileDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'ERROR_MESSAGES.ONE_FILE_IS_REQUIRED',
        },
      },
      attachment: {
        invoice: [],
        warrantyCard: [],
      },
      attachmentChecker: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'ERROR_MESSAGES.FILE_NOT_VALID',
        },
      },
      formHeaderBackgroundColor: 'primary',
      formName: 'MobilProviderGaranciaFormTelekom',
      selectFieldOptions: {
        brands: [],
        productGroups: [],
        productTypes: [],
        warrantyType: [],
        networkLock: [],
        paymentMethods: [],
        origRequestSource: this.$store.state.selectfields.origRequestSource,
        garancia: [
          {
            id: 1,
            name: 'Garanciális javítás',
          },
          {
            id: 2,
            name: 'Fizetős javítás',
          },],
        garanciaEN: [
          {
            id: 1,
            name: 'In warranty service',
          },
          {
            id: 2,
            name: 'Out of warranty service',
          },
        ],
        garanciaDE: [
          {
            id: 1,
            name: 'Garantiereparatur',
          },
          {
            id: 2,
            name: 'Nein Garantiereparatur',
          },
        ],
        /*networkLock: this.$store.state.selectfields.simLock,
        networkLockEN: this.$store.state.selectfields.simLockEN,
        networkLockDE: this.$store.state.selectfields.simLockDE,*/
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        customerShippingAddresses: [],
        customerBillingAddresses: [],
        // számlázási címhez selectek
        postalCodesInvoice: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesInvoice: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsInvoice: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        /*paymentMethods: this.$store.state.selectfields.paymentMethods,
        paymentMethodsEN: this.$store.state.selectfields.paymentMethodsEN,
        paymentMethodsDE: this.$store.state.selectfields.paymentMethodsDE,*/
      },
      inputRules: {
        acceptInformation: [v => !!v || this.$t('inputMessages.acceptInformation')],
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        lenghtRules: [v => v.length <= 3000 || this.$t('inputMessages.maxCharacter'),
          v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('inputMessages.wrongCharacter'),
        ],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        gdprRules: [
          v => !!v || this.$t('inputMessages.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('inputMessages.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        min8: [
          v => v.length >= 8 || this.$t('inputMessages.passwordRuleMin8'),
          v => !!v || this.$t('inputMessages.requiredField')
        ],
        imeiRule: [
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        snRule: [
          v => /^[a-zA-Z0-9\-\/\\]*$/.test(v) || this.$t('inputMessages.snRules'),
        ],
        vatRules: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => (v && v.length === 13) || this.$t('inputMessages.requiredField'),
        ],
        noSpecialCharAndReq: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.requiredFieldNoSpecialChar'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    async CheckIsUserCanSeeTheForm() {
      if (this.$store.state.authModule.isAuthenticated) {
        if (this.$store.state.authModule.user) {
          if (this.$store.state.authModule.user.profile.customerType) {
            return true;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    async getBrand() {
      //
    },
    setSelectedService() {
      //
    },
    async setFormHeaderBackgroundColorAndFormName() {
      switch (this.mobilProviderId) {
        case 1:
          this.formHeaderBackgroundColor = 'telekomPrimary';
          this.formName = 'MobilProviderGaranciaFormTelekom';
          break;
        case 2:
          this.formHeaderBackgroundColor = 'telenorPrimary';
          this.formName = 'MobilProviderGaranciaFormTelenor';
          break;
        case 3:
          this.formHeaderBackgroundColor = 'vodafonePrimary';
          this.formName = 'MobilProviderGaranciaFormVodafone';
          break;
        default:
          this.formHeaderBackgroundColor = 'primary';
          this.formName = 'MobilProviderGaranciaFormTelekom';
          break;
      }
    },
    async setDates() {
      const now = new Date();
      const date = dayjs(now).subtract(3, 'day');
      this.maxDate = date.toISOString().substr(0, 10);
    },
    async setServiceRequestFormCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
    },
    async setWarrantyType() {
      this.serviceRequestForm.serviceRequest.warrantyType = this.selectFieldOptions.warrantyType[0];
    },
    async setNetworkLock() {
      this.serviceRequestForm.serviceRequest.networkLock = this.selectFieldOptions.networkLock[0];
    },
    async setAvailableServiceIdAndFormType() {
      const item = this.brand.availableService.find(item => {
        return item.warrantyTypeId === 1;
      })
      if (item) {
        this.serviceRequestForm.serviceRequest.availableServiceId = item.id;
        this.serviceRequestForm.formType = item.formType;
      }
    },
    async setCustomerDatas() {
      this.serviceRequestForm.customer.firstName = this.$store.state.authModule.user.profile.firstName;
      this.serviceRequestForm.customer.lastName = this.$store.state.authModule.user.profile.lastName;
      this.serviceRequestForm.customer.email = this.$store.state.authModule.user.profile.email;
      this.serviceRequestForm.customer.phoneNumber = this.$store.state.authModule.user.profile.phone;
      this.serviceRequestForm.customer.id = getUid();
    },
    setCustomerAddresses() {
      this.$store.state.authModule.user.addresses.forEach((item, index) => {
        let temp = {};
        if (item.addressType.id === 1) {
          temp.id = item.id;
          temp.country = {
            countryCode: item.countryCode,
            label: item.countryLabel,
          };
          temp.city = item.city;
          temp.postalCode = item.zipCode;
          temp.publicPlaceName = item.publicPlaceName;
          temp.publicPlaceType = item.publicPlaceType;
          temp.streetNumber = item.houseNumber;
          temp.other = item.otherAddressInfo;
          temp.label = `${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
          temp.billingName = '';
          temp.taxNumber = null;
          temp.accountNumber = null;
          temp.isCompany = item.isCompany;
          this.selectFieldOptions.customerShippingAddresses.push(temp);
        } else if (item.addressType.id === 2) {
          temp.id = item.id;
          temp.country = {
            countryCode: item.countryCode,
            label: item.countryLabel,
          };
          temp.city = item.city;
          temp.postalCode = item.zipCode;
          temp.publicPlaceName = item.publicPlaceName;
          temp.publicPlaceType = item.publicPlaceType;
          temp.streetNumber = item.houseNumber;
          temp.other = item.otherAddressInfo;
          temp.billingName = item.billingName;
          temp.taxNumber = null;
          temp.accountNumber = null;
          temp.isCompany = item.isCompany;
          temp.label = `${item.billingName} - ${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
          this.selectFieldOptions.customerBillingAddresses.push(temp);
        }
      });
      this.selectFieldOptions.customerShippingAddresses.push({id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg'});
      this.selectFieldOptions.customerBillingAddresses.push({id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg'});
    },
    setProcessType() {
      this.serviceRequestForm.processType = 1;
      if (getToken() && getUid()) {
        if (!this.serviceRequestForm.deliveryInAddress.id && !this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 4;
        } else if (!this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 3;
        } else if (!this.serviceRequestForm.deliveryInAddress.id) {
          this.serviceRequestForm.processType = 2;
        } else {
          this.serviceRequestForm.processType = 5;
        }
      }
    },
    nextStep() {
      this.step += 1;
    },
    async sendContactForm() {
      this.openLoadingDialog();
      this.serviceRequestForm.deliveryOutAddress = this.serviceRequestForm.deliveryInAddress;
      this.serviceRequestForm.billingAddress = this.serviceRequestForm.deliveryInAddress;
      this.serviceRequestForm.serviceRequest.mobilProvider = this.serviceRequestForm.serviceRequest.networkLock;
      this.setProcessType();
      if (this.serviceRequestForm.accountRegistration === 'no' || this.serviceRequestForm.accountRegistration === null) {
        this.serviceRequestForm.customer.password = null;
      }
      // Purchase Price ellenőrzése
      if (this.serviceRequestForm.serviceRequest.purchasePrice === '') {
        this.serviceRequestForm.serviceRequest.purchasePrice = null;
      }
      if (this.serviceRequestForm.serviceRequest.purchasePrice) {
        if (this.serviceRequestForm.serviceRequest.purchasePrice.length < 1) {
          this.serviceRequestForm.serviceRequest.purchasePrice = null;
        }
      }
      // duplikáció ellenőrzése
      const duplicationResponse = await this.$store.dispatch('CheckDuplication',
        {
          email: this.serviceRequestForm.customer.email,
          identifiers: [
            this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1,
            this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2,
            this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3,
          ]
        }
      );
      if (duplicationResponse !== 400) {
        const response = await this.$store.dispatch('sendMobilfutarRequest',
            { form: this.serviceRequestForm, attachment: this.attachment });
        // console.log('sendContactForm response: ', response);
        this.responseDialog.response.data = response;
        if (response.status === 200) {
          this.responseDialog.response.error = false;
          this.responseDialog.response.message = 'SUCCESS_MESSAGES.REQUEST_SEND';
        } else if (response.status === 201) {
          this.responseDialog.response.error = false;
          this.responseDialog.response.message = 'SUCCESS_MESSAGES.REQUEST_SEND';
        } else if (response.status === 403) {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = 'ERROR_MESSAGES.USER_EXISTS';
        } else if(response.status === 405) {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = 'ERROR_MESSAGES.REQUEST_SEND_1';
        } else {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = 'ERROR_MESSAGES.REQUEST_SEND_2';
        }
      } else {
        this.responseDialog.response.error = true;
        this.responseDialog.response.message = 'ERROR_MESSAGES.DUPLICATION';
      }
      this.controlDialogs();
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      if (this.responseDialog.response.data.status === 403) {
        this.$router.push('/bejelentkezes');
      } else {
        if (!this.responseDialog.response.error) {
          this.$router.push('/');
        }
      }
    },
    closeNoFileDialog() {
      this.noFileDialog.visible = false;
    },
    closeNotValidPdfDialog() {
      this.attachmentChecker.visible = false;
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3200);
    },
    async imeiCheck() {
      /*const response = await this.$store.dispatch(LUHN_CHECK, this.serviceRequestForm.serviceRequest.deviceIdentifier);
      if (response) {
        // console.log('LUHN_CHECK: ', response);
        if (response.error) {
          this.imeiHint = 'imeiError';
        } else {
          this.imeiHint = response.message;
        }
      }*/
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.serviceRequestForm.deliveryInAddress.postalCode && this.serviceRequestForm.deliveryInAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.deliveryInAddress.postalCode.address.postalCode;
        this.serviceRequestForm.deliveryInAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.cities = [];
        } else {
          this.selectFieldOptions.cities = response.data;
          this.citySearchIsLoading = false;
          this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
        }
      }
    },
    async getPostalCodeByCity(value) {
      if (this.serviceRequestForm.deliveryInAddress.postalCode === null) {
        this.serviceRequestForm.deliveryInAddress.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY(value, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.deliveryInAddress.city, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.serviceRequestForm.deliveryInAddress.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.serviceRequestForm.deliveryInAddress.postalCode = '';
      this.serviceRequestForm.deliveryInAddress.city = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
    },
    saveDeliveryInAddressData() {
      this.deliveryInAddressString = this.serviceRequestForm.deliveryInAddress.postalCode + ' '
          + this.serviceRequestForm.deliveryInAddress.city + ', '
          + this.serviceRequestForm.deliveryInAddress.publicPlaceName + ' '
          + this.serviceRequestForm.deliveryInAddress.publicPlaceType + ' '
          + this.serviceRequestForm.deliveryInAddress.streetNumber;
      this.deliveryInAddressModal = false;
    },
    /***
     * SZÁMLÁZÁSI CÍM MEGADÁSÁHOZ A FGV-EK
     */
    async getPostalCodesByCountryInvoice(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesInvoice = response.data;
        this.postalCodeSearchIsLoadingInvoice = false;
        this.selectFieldOptions.postalCodesInvoice.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeInvoice() {
      let postalcode = 0;
      if (this.serviceRequestForm.billingAddress.postalCode && this.serviceRequestForm.billingAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.billingAddress.postalCode.address.postalCode;
        this.serviceRequestForm.billingAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.citiesInvoice = [];
        } else {
          this.selectFieldOptions.citiesInvoice = response.data;
          this.citySearchIsLoadingInvoice = false;
          this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
        }
      }
    },
    async getPostalCodeByCityInvoice(value) {
      if (this.serviceRequestForm.billingAddress.postalCode === null) {
        this.serviceRequestForm.billingAddress.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY(value, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesInvoice = response.data;
        this.citySearchIsLoadingInvoice = false;
        this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCodeInvoice(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.billingAddress.city, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsInvoice = response.data;
        this.streetSearchIsLoadingInvoice = false;
        this.selectFieldOptions.streetsInvoice.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectInvoice() {
      await this.getCityByPostalCodeInvoice();
      if (this.selectFieldOptions.citiesInvoice.length === 2) {
        this.serviceRequestForm.billingAddress.city = this.selectFieldOptions.citiesInvoice[1].address.city;
        this.citySearchInvoice = this.selectFieldOptions.citiesInvoice[1].address.city;
      }
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
      this.streetIdeiglenesInvoice = '';
      this.streetSearchInvoice = null;
    },
    onPublicPlaceSelectInvoice() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.length > 0) {
        street = this.streetIdeiglenesInvoice.substring(0, this.streetIdeiglenesInvoice.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.substring(this.streetIdeiglenesInvoice.lastIndexOf(' '), this.streetIdeiglenesInvoice.length + 1).trim();
        if (this.streetIdeiglenesInvoice.lastIndexOf(' ') > 0) {
          // console.log('3a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          // console.log('3b');
          this.serviceRequestForm.billingAddress.publicPlaceName = this.streetIdeiglenesInvoice;
          type = this.selectFieldOptions.streetsInvoice[1].address.street.substring(this.selectFieldOptions.streetsInvoice[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsInvoice[1].address.street.length + 1).trim();
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.address) {
        street = this.streetIdeiglenesInvoice.address.street.substring(0, this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.address.street.substring(this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '), this.streetIdeiglenesInvoice.address.street.length + 1).trim();
        if (this.streetIdeiglenesInvoice.address.street.lastIndexOf(' ') > 0) {
          // console.log('4a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          console.log('4b');
        }
      }
    },
    clearAllAddressInputsInvoice() {
      this.selectFieldOptions.postalCodesInvoice = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesInvoice = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsInvoice = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearchInvoice = '';
      this.postalCodeSearchIsLoadingInvoice = false;
      this.citySearchInvoice = '';
      this.citySearchIsLoadingInvoice = false;
      this.streetSearchInvoice = '';
      this.streetSearchIsLoadingInvoice = false;
      this.streetIdeiglenesInvoice = '';
      this.serviceRequestForm.billingAddress.postalCode = '';
      this.serviceRequestForm.billingAddress.city = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
    },
    saveBillingAddressData() {
      this.billingAddressString = this.serviceRequestForm.billingAddress.billingName
          + ' - ' + this.serviceRequestForm.billingAddress.postalCode + ' '
          + this.serviceRequestForm.billingAddress.city + ', '
          + this.serviceRequestForm.billingAddress.publicPlaceName + ' '
          + this.serviceRequestForm.billingAddress.publicPlaceType + ' '
          + this.serviceRequestForm.billingAddress.streetNumber;
      this.billingAddressModal = false;
    },
    openBillingAddressModal() {
      // a meglévő szállítási címet kimentjük egy ideiglenes változóba,
      // hogy ha a user a Mégsem gombra kattint, akkor megmaradjon az előzőleg beírt cím
      this.billingAddressBackup = this.serviceRequestForm.billingAddress;
      this.billingAddressModal = true;
    },
    closeBillingAddressModalWithoutSave() {
      // Mégsem gombra kattintva visszaszerezzük az előzőleg beírt címet
      this.serviceRequestForm.billingAddress = this.billingAddressBackup;
      this.billingAddressModal = false;
      this.billingAddressBackup = null;
    },
    async callShowForm() {
      const response = await this.$store.dispatch('showForm', this.formName);
      if (response.status === 200) {
        this.selectFieldOptions.warrantyType = response.data.warrantyType;
        this.selectFieldOptions.networkLock = response.data.networkLock;
        this.selectFieldOptions.brands = response.data.brand;
      }
    },
    async callShowPaymentMethodOfCustomerType() {
      const pm = this.$store.state.authModule.user ? this.$store.state.authModule.user.profile.customerType : 'B2C';
      const response = await this.$store.dispatch('showPaymentMethodOfCustomerType', pm);
      if (response.status === 200) {
        this.selectFieldOptions.paymentMethods = response.data;
      }
    },
    async callShowProductGroupOfBrand(brand) {
      const resp = await this.$store.dispatch('showProductGroupOfBrand', brand.id);
      // console.log('resp: ', resp);
      this.selectFieldOptions.productGroups = resp.data;
    },
    async callShowProductTypeOfBrand() {
      const response = await this.$store.dispatch('showProductTypeOfBrand', { brandId: this.serviceRequestForm.serviceRequest.brand.id, productGroupId: this.serviceRequestForm.serviceRequest.productGroup.id });
      // console.log('callShowProductTypeOfBrand: ', response);
      if (response.status === 200) {
        this.selectFieldOptions.productTypes = response.data;
      }
    },
    pdfChecker(file, type) {
      const pdfRegexp = new RegExp('%PDF-1.[0-7]');
      // const pdfRegexp = new RegExp('^%PDF');
      const size = file.size;
      let header = '';
      let scope = this;
      let fr = new FileReader();
      fr.readAsText(file);
      fr.onload = function (evt) {
        header = evt.target.result.substr(0, 8);
        // console.log('header: ', header);
        if (pdfRegexp.test(header) && size > 0) {
          // console.log('%cOK', 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
          scope.attachmentChecker.response.error = false;
        } else {
          // console.log('%cNEM OK', 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
          scope.attachmentChecker.visible = true;
          scope.attachmentChecker.response.error = true;
          if (type === 1) {
            scope.attachment.invoice = null;
          } else {
            scope.attachment.warrantyCard = null;
          }
        }
      };
    },
  },
  watch: {
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    citySearch(value) {
      this.citySearchIsLoading = true;
      this.getPostalCodeByCity(value);
    },
    citySearchInvoice(value) {
      this.citySearchIsLoadingInvoice = true;
      this.getPostalCodeByCityInvoice(value);
    },
    streetSearch(value) {
      if (value.length >= 1) {
        this.streetSearchIsLoading = true;
        this.getStreetByPostalCode(value);
      }
    },
    postalCodeSearchInvoice(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryInvoice(value);
    },
    streetSearchInvoice(value) {
      if (value.length >= 1) {
        this.streetSearchIsLoading = true;
        this.getStreetByPostalCodeInvoice(value);
      }
    },
    billingAddressIsEqualdeliveryInAddress(value) {
      if (value) {
        this.serviceRequestForm.billingAddress.billingName = this.serviceRequestForm.customer.lastName + ' ' + this.serviceRequestForm.customer.firstName;
        this.serviceRequestForm.billingAddress.postalCode = this.serviceRequestForm.deliveryInAddress.postalCode;
        this.serviceRequestForm.billingAddress.city = this.serviceRequestForm.deliveryInAddress.city;
        this.serviceRequestForm.billingAddress.publicPlaceName = this.serviceRequestForm.deliveryInAddress.publicPlaceName;
        this.serviceRequestForm.billingAddress.publicPlaceType = this.serviceRequestForm.deliveryInAddress.publicPlaceType;
        this.serviceRequestForm.billingAddress.streetNumber = this.serviceRequestForm.deliveryInAddress.streetNumber;
        this.serviceRequestForm.billingAddress.other = this.serviceRequestForm.deliveryInAddress.other;
        this.saveBillingAddressData();
      } else {
        this.clearAllAddressInputsInvoice();
        this.serviceRequestForm.billingAddress.billingName = '';
        this.serviceRequestForm.billingAddress.taxNumber = '';
        this.serviceRequestForm.billingAddress.other = '';
        this.serviceRequestForm.billingAddress.id = null;
        this.billingAddressString = '';
      }
    },
    'serviceRequestForm.serviceRequest.brand': function (newVal) {
      this.serviceRequestForm.serviceRequest.productGroup = {};
      this.serviceRequestForm.serviceRequest.productTypeFull = null;
      this.serviceRequestForm.serviceRequest.productType = null;
      this.callShowProductGroupOfBrand(newVal);
    },
    'serviceRequestForm.serviceRequest.productGroup': function (newVal) {
      // console.log('newVal: ', newVal);
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = null;
      this.serviceRequestForm.serviceRequest.productTypeFull = null;
      // this.selectedProductGroup = this.serviceRequestForm.serviceRequest.productGroup.find(item => item.productGroup.id === newVal.id);
      if (newVal.id) {
        this.callShowProductTypeOfBrand();
      }
    },
    'serviceRequestForm.serviceRequest.productTypeFull': function (newVal) {
      this.serviceRequestForm.serviceRequest.productType = {};
      if (newVal) {
        this.serviceRequestForm.serviceRequest.productType.id = newVal.productTypeId;
        this.serviceRequestForm.serviceRequest.productType.name = newVal.productTypeShoppingName;
      }
    },
    /* 'serviceRequestForm.serviceRequest.warrantyType': function (newVal) {
      if (newVal.id === 1) {
        this.serviceRequestForm.serviceRequest.paymentMethod = { id: null, name: null, lang: null};
      } else {
        this.serviceRequestForm.serviceRequest.paymentMethod = null;
      }
    }, */
    customerShippingAddress(value) {
      // console.log('customerShippingAddress: ', value);
      if (value.id === '5dC]Y??9f@TmbJFv') {
        this.shippingAddressStepper = 2;
        this.serviceRequestForm.deliveryInAddress = {
          id: null,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
          isCompany: 0,
        };
        this.setServiceRequestFormCountry();
      } else {
        // this.serviceRequestForm.deliveryInAddress.id = value.id;
        this.serviceRequestForm.deliveryInAddress = value;
        this.deliveryInAddressString = value.label;
        this.deliveryInAddressModal = false;
        this.deliveryInAddressForm = true;
      }
    },
    customerBillingAddress(value) {
      // console.log('customerBillingAddress: ', value);
      if (value.id === '5dC]Y??9f@TmbJFv') {
        this.billingAddressStepper = 2;
        this.serviceRequestForm.billingAddress = {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        };
        this.setServiceRequestFormCountry();
      } else {
        this.serviceRequestForm.billingAddress = value;
        this.billingAddressString = value.label;
        this.billingAddressModal = false;
        this.billingAddressForm = true;
      }
    },
    /* 'attachment.invoice': async function(value) {
      console.log('attachment.invoice: ', value);
      if (value) {
        console.log('van file');
        this.pdfChecker(value, 1);
      }
    },
    'attachment.warrantyCard': async function(value) {
      console.log('attachment.warrantyCard: ', value);
      if (value) {
        console.log('van file');
        this.pdfChecker(value, 2);
      }
    }, */
  },
};
</script>

<style scoped>

</style>
