<template>
  <v-container class="my-12">
    <v-row justify="center">
      <v-col cols="12" class="text-center subtitle-1">
        {{ $t(`${loadingMessage}`) }}
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular color="primary"
                             indeterminate>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoadingComponent',
  components: {
    //
  },
  props: {
    loadingMessage: {
      type: String,
      default: 'loadingMessages.INPROGRESS_FORMLOADING',
    }
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
