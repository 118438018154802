<template>
  <v-card>
    <v-card-text class="pa-8 text-center">
      <div class="body-1" v-html="$t(loadingMessage)"></div>
    </v-card-text>
    <v-progress-linear
      indeterminate
      color="primary"
      height="8"/>
  </v-card>
</template>

<script>
export default {
  name: 'loadingDialog',
  props: {
    loadingMessage: String,
  },
};
</script>

<style scoped>

</style>
