<template>
  <div v-frag>
    <hero-with-image :title="pageTitle"
                     :subtitle="mobilProviderId === 3 ? 'vodafone.warrantyRepairForm' : 'warrantyRepairForm'"
                     :image="imagePath">
    </hero-with-image>
    <template v-if="brand.mobilProviderId && brand.mobilProviderId === 1">
      <telekom-garancias-form v-if="pageTitle !== 'Flip'"
                              :brand="brand"
                              :mobil-provider-id="mobilProviderId"
                              :mobil-provider-name="mobilProviderName">
      </telekom-garancias-form>
      <flip-garancias-form v-else
                           :brand="brand"
                           :mobil-provider-id="mobilProviderId"
                           :mobil-provider-name="mobilProviderName">
      </flip-garancias-form>
    </template>
    <template v-else-if="brand.mobilProviderId && brand.mobilProviderId === 2">
      <telenor-garancias-form :brand="brand" :mobil-provider-id="mobilProviderId" :mobil-provider-name="mobilProviderName"></telenor-garancias-form>
    </template>
    <template v-else-if="brand.mobilProviderId && brand.mobilProviderId === 3">
      <vodafone-garancias-form :brand="brand" :mobil-provider-id="mobilProviderId" :mobil-provider-name="mobilProviderName"></vodafone-garancias-form>
    </template>
    <template v-else>
      <div>
        a vodafone űrlap lenne itt, de azokat, még aktualizálni kell.
      </div>
    </template>
  </div>
</template>

<script>
import FlipGaranciasForm from 'src/components/forms/FlipGaranciasForm.vue';
import HeroWithImage from 'src/components/titles/HeroWithImage';
import MobilProviderGaranciaForm from 'src/components/forms/MobilProviderGaranciaForm';
import TelekomGaranciasForm from 'src/components/forms/TelekomGaranciasForm.vue';
import TelenorGaranciasForm from 'src/components/forms/TelenorGaranciasForm.vue';
import VodafoneGaranciasForm from 'src/components/forms/VodafoneGaranciasForm.vue';

export default {
  name: 'MobilProviderGaranciaPage',
  components: {
    FlipGaranciasForm, HeroWithImage, MobilProviderGaranciaForm, TelekomGaranciasForm, TelenorGaranciasForm, VodafoneGaranciasForm,
  },
  props: {
    mobilProviderId: {
      type: Number,
      default: 999,
    },
    mobilProviderName: {
      type: String,
      default: 'EMPTY',
    },
  },
  async beforeMount() {
    if (this.$store.state.mobilProviderManagement.selectedBrand && this.mobilProviderId === this.$store.state.mobilProviderManagement.selectedBrand.mobilProviderId) {
      this.brand = this.$store.state.mobilProviderManagement.selectedBrand
    } else {
      this.brand = await this.getMobilProvider();
      // console.log('GARANCIA PAGE mobilProvider: ', this.brand);
    }
    if (this.$route.name === 'flip-garancialis-javitas') {
      this.pageTitle = 'Flip';
      this.imagePath = 'data/crm/mobil_provider/flip640x640.png';
    } else {
      this.pageTitle = this.brand.mobilProviderName;
      this.imagePath = this.brand.imagePath;
    }
  },
  data() {
    return {
      brand: {},
      pageTitle: '',
      imagePath: '',
    };
  },
  methods: {
    async getMobilProvider() {
      // console.log('getMobilProvider ...');
      return await this.$store.dispatch('getMobilProviderById', this.mobilProviderId);
    },
    async initComp() {
      //
    },
  },
  watch: {
    //
  },
};
</script>

<style scoped>

</style>
