<template>
  <v-container class="my-12">
    <v-row class="my-12 fill-height" align="center" justify="center">
      <v-col cols="12" class="text-center mb-5">
        <h2>
          {{ $store.state.authModule.isAuthenticated ? $t('customerTypeCantSeeTheContent') : $t('needToLogin') }}
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-btn class="primary" @click="buttonClick">
          {{ $store.state.authModule.isAuthenticated ? $t('button.goToDashboard') : $t('button.goToLogin') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserCanSeeTheContent',
  components: {
    //
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    buttonClick() {
      if (this.$store.state.authModule.isAuthenticated) {
        this.$router.push({name: 'iranyitopult'});
      } else {
        this.$router.push({name: 'login'});
      }
    }
  },
};
</script>

<style scoped>

</style>
