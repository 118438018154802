import { render, staticRenderFns } from "./FlipGaranciasForm.vue?vue&type=template&id=66a469b9&scoped=true&"
import script from "./FlipGaranciasForm.vue?vue&type=script&lang=js&"
export * from "./FlipGaranciasForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a469b9",
  null
  
)

export default component.exports